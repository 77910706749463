import React from 'react';

// Chart.j
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line, Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const CassetteStepGraph = (props) => {

	const allLabels = ['1-2', '2-3', '3-4', '4-5', '5-6', '6-7', '7-8', '8-9', '9-10', '10-11', '11-12', '12-13'];
	
	let cassetteSize = props.cassetteSteps.length;
	if (cassetteSize === 0)
		cassetteSize = allLabels.length;
	const labels = allLabels.slice(0, cassetteSize);

	const options = {
	  responsive: true,
	  plugins: {
		legend: {
		  position: 'top',
		},
		title: {
		  display: true,
		  text: 'Cassette Details',
		  font: {
		  	size: 24,
// 		  	family: '"Century Gothic", Futura, sans-serif',
		  	weight: 'normal',
		  	},
		},
		},
		scales: {
			y1: {
				display: true,
				position: 'left',
				title: {
					display: true,
					text:'Gear Step',
					},
				min: 0,
				ticks: {
					stepSize: 1,
//  				min: 0,    // minimum will be 0, unless there is a lower value.
					// OR //
					beginAtZero: true   // minimum value will be 0.
				}
			},
			y2: {
				display: true,
				position: 'right',
				title: {
					display: true,
					text:'Step as Percent',
					},
				min: 0,
				ticks: {
					stepSize: 1,
// 					min: 0,    // minimum will be 0, unless there is a lower value.
					// OR //
					beginAtZero: true   // minimum value will be 0.
				}
			},
			x: {
				display: true,
				title: {
					display: true,
					text: "Cassette Gear Step",
					},
				},
			
	  },
	};
	
	const data = {
	  labels,
	  datasets: [
		{
		  label: 'Cassette Step Size',
		  yAxisID: 'y1',
		  data: props.cassetteSteps,
		  borderColor: 'rgb(255, 99, 132)',
		  backgroundColor: 'rgba(255, 99, 132, 1.0)',
		},
		{
		  label: 'Cassette Step Size as %',
		  yAxisID: 'y2',
		  data: props.cassetteStepsPercent,
		  borderColor: 'rgb(132, 255, 99)',
		  backgroundColor: 'rgba(132, 255, 99, 1.0)',
		},
	  ],
	};
		
	return <Line options={options} data={data} height={100} />;
}

export const GearSizeGraph = (props) => {

	const labels = props.cassetteGears;
	
	const yAxisTitle = "Wheel Size (in)";
	const xAxisTitle = "Rear Gear Size";
	const graphTitle = "Effective Wheel Size";
	
	const data1 = props.effective.slice(0, labels.length);
	const data2 = props.effective.slice(labels.length, (labels.length * 2));
	const data3 = props.effective.slice((labels.length * 2), (labels.length * 3));
	
	const dataset1 = {
		  label: props.chainsetGears[0] + ' Ring',
		  data: data1,
		  borderColor: 'rgb(255, 99, 132)',
		  backgroundColor: 'rgba(255, 99, 132, 0.5)',
		};
		
	const dataset2 = {
		  label: props.chainsetGears[1] + ' Ring',
		  data: data2,
		  borderColor: 'rgb(99, 255, 132)',
		  backgroundColor: 'rgba(99, 255, 132, 0.5)',
		};

	const dataset3 = {
		  label: props.chainsetGears[2] + ' Ring',
		  data: data3,
		  borderColor: 'rgb(99, 132, 255)',
		  backgroundColor: 'rgba(99, 132, 255, 0.5)',
		};
		
	// Setup the datasets - assume we'll always have 1 chainring and no more than 3
	const datasets = [dataset1];
	if (data2.length > 0) {
		datasets.push(dataset2);
	}
	if (data3.length > 0) {
		datasets.push(dataset3);
	}

	const options = {
	  responsive: true,
	  plugins: {
		legend: {
		  position: 'top',
		},
		title: {
		  display: true,
		  text: graphTitle,
		  font: {
// 		  	family: '"Century Gothic", Futura, sans-serif',
		  	weight: 'normal',
			size: 24,
		  	},
		},
		},
		scales: {
			y: {
				display: true,
				min: 0,
				ticks: {
					stepSize: 1,
// 					min: 0,    // minimum will be 0, unless there is a lower value.
					// OR //
					beginAtZero: true   // minimum value will be 0.
				},
				title: {
					display: true,
					text: yAxisTitle,
					},

			},
			x: {
				display: true,
				title: {
					display: true,
					text: xAxisTitle,
					},
				},
	  },
	};
	
	const data = {
	  labels,
	  datasets: datasets,
	};
		
	return <Bar options={options} data={data} height={100} />;
}