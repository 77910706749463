import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';

import Version from '../package.json';
import bikecassetteIcon from './bikecassette.png';



export function TopNavBar(props) {

	
	const BikesDropdown = (props) => {
		let dropdown = '';
		let dropdowns = [];
		props.bikesArray.forEach((item, index) => {
			let dropdownItem = <NavDropdown.Item key={index} eventKey={index} href="#action">{item.name}</NavDropdown.Item>;
			dropdown = dropdown + dropdownItem;
			dropdowns.push(dropdownItem);
		});
		return dropdowns;
	}

	return (
		<Navbar bg="light" onSelect={props.onSelect}>
			<Container>
				<Navbar.Brand href="#home">
					<img
					  alt=""
					  src={bikecassetteIcon}
					  width="30"
					  className="d-inline-block align-center"
					/>{' '}
					GearCalc
					</Navbar.Brand>
					<Nav.Link disabled >V{ Version.version }</Nav.Link>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
				  <NavDropdown title="My Bikes" id="basic-nav-dropdown">
				  	<BikesDropdown bikesArray={props.bikesArray} />
					  <NavDropdown.Divider />
					  <NavDropdown.Item eventKey={99}>
						Save Bike
					  </NavDropdown.Item>
					  <NavDropdown.Item eventKey={999}>
						Manage Saved Bikes
					  </NavDropdown.Item>					  
				  </NavDropdown>

				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
			
}

export function BottomNavBar() {
	return (
		<Navbar bg="light" fixed="bottom">
			<Container>
			  <Nav className="me-auto">
				<Nav.Link disabled >© Stuart Tevendale {(new Date().getFullYear())}</Nav.Link>
				<Nav.Link disabled >V{ Version.version }</Nav.Link>
			  </Nav>
			</Container>
		</Navbar>
	);







}
