import React from 'react';
import Select from 'react-select';
import {readCassetteData} from './CassettePopup';

var cassettes = [];

export function ManufacturerPopup(props) {
	cassettes = readCassetteData();
	const options = manufacturerPopupRows();
	let cookieManu = props.manufacturer;
	// Find the index of the selected Manufacturer
	var cookieIndex = -1;	
	for (var i = 0; i < options.length; i++) {
		if (options[i].label === cookieManu){
			cookieIndex = i;
		}
	}

	  return (
		<div className="mt-3">
			Select your cassette manufacturer
		  <Select
			onChange={props.onChange}
		  	options={options}
		  	value={options[cookieIndex]}
		  />
		</div>
	  );
}

function manufacturerPopupRows() {
	var uniqueManufacturers = unique2();
	var popupRows = [];
	for (var i = 0; i < uniqueManufacturers.length; i++) {
		let manufacturersName = uniqueManufacturers[i];
		popupRows.push({value:i, label:manufacturersName});
		}
	
	return popupRows;
}

function unique2() {
	var uniques = new Set([]);
	cassettes.forEach(tag => {
		uniques.add(tag.manufacturer);
		});
		
	return Array.from(uniques);
}

