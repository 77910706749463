import React from 'react';

import { saveAs } from 'file-saver';

// Bootstrap Imports
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import 'bootstrap/dist/css/bootstrap.min.css';


export function SaveBikeDialog(props) {

	return (
		<Modal
		  show={props.show}
		  onHide={props.onHide}
		  size="lg"
		  aria-labelledby="save-bike-dialog"
		  centered
		>
		  <Modal.Header closeButton>
			<Modal.Title id="save-bike-dialog">
			  Save This as a New Bike
			</Modal.Title>
		  </Modal.Header>
		  <Modal.Body>
			Add this setup as a bike. The data is saved in your browser's cookies.
			  <Form>
				<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
				<h5>Bike Name</h5>
				  <Form.Control
					type="text"
					placeholder="My Cervelo S5"
					autoFocus
					onChange={props.onNameChange}
				  />
				</Form.Group>
			  </Form>
		  </Modal.Body>
		  <Modal.Footer>
			<Button variant="primary" onClick={props.onHide}>Save</Button><Button variant="secondary" onClick={props.onCancel}>Cancel</Button>
		  </Modal.Footer>
		</Modal>
	);
}

export class EditBikeDialog extends React.Component {

	componentDidMount() {
		this.setState({selected: 0});

	}
	
	onChange = (e) => {
		this.setState({selected: e.target.value});
	}
	
	onClickedExport = (e) => {
		const bike = this.props.bikesArray[this.state.selected];
		const stringValue = JSON.stringify(bike);
		const filename = bike.name + ".json";
		
		var blob = new Blob([stringValue], {type: "text/plain;charset=utf-8"});
		saveAs(blob, filename);
	}
	
	onDelete = (e) => {
		this.props.onDelete(this.state.selected)
	}

	render() {

		return (
		<Modal
		  show={this.props.show}
		  onHide={this.props.onHide}
		  size="lg"
		  aria-labelledby="edit-bike-dialog"
		  centered
		>
		  <Modal.Header closeButton>
			<Modal.Title id="edit-bike-dialog">
			  Edit a Saved Bike
			</Modal.Title>
		  </Modal.Header>
		  <Modal.Body>
			<h5>Delete or Export a saved bike.</h5>
			Your saved bikes are stored in your browser's cookies. This dialog lets exports each bike as JSON or to delete a bike.
			  <Form>
				<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
				<h5>Select a Bike</h5>
				<Form.Select aria-label="Default select example" onChange={this.onChange}>
					<BikesDropdown bikesArray={this.props.bikesArray} />
				</Form.Select>

				</Form.Group>
			  </Form>
		  </Modal.Body>
		  <Modal.Footer>
			<Button variant="warning" onClick={this.onDelete}>Delete</Button><Button variant="primary" onClick={this.onClickedExport}>Export</Button><Button variant="secondary" onClick={this.props.onCancel}>Close</Button>
		  </Modal.Footer>
		</Modal>
		)
	};
}

const BikesDropdown = ({bikesArray}) => {
	console.log(bikesArray);
		let dropdown = '';
		let dropdowns = [];
		bikesArray.forEach((item, index) => {
			let dropdownItem = <option key={index} value={index}>{item.name}</option>;
			dropdown = dropdown + dropdownItem;
			dropdowns.push(dropdownItem);
		});
		return dropdowns;
	}
