import React from 'react';
import Select from 'react-select';
import {setCookie} from './cookie';

import cassetteData from './cassettes.json';

import sortArray from 'sort-array'

var cassettes = [];

export function CassettePopup(props) {
	readCassetteData();
	const options = cassettePopupRows(props.manufacturer);
	
	var itemToShow = {value: -1, label: 'Select...'};
	
	const selectedItem = props.cassette;
	// Hash of 0 indicates a custom cassette
	if (selectedItem !=='' && selectedItem !== 0) { 
		const selectedCassette = cassetteFromHash(selectedItem);
		if (selectedCassette) {

			// Find the index of the selected Manufacturer
			var cookieIndex = -1;
				console.log(selectedCassette);
				options.forEach((item, index) => {
					if (item.label === selectedCassette.name()){
						cookieIndex = index;
					}
				});
	
			if (cookieIndex >= 0) {
				itemToShow = options[cookieIndex];
			}
		}
	}
	
	if (selectedItem === 0) {
			itemToShow = {value: -1, label: 'Custom...'};
	 		setCookie("cassette", 0, 365);
	}


	  return (
		<div className="mt-3">
			Select your cassette, or enter your own sizes below for a custom setup
		  <Select
		  	onChange={props.onChange}
		  	options={options}
		  	value={itemToShow}
		  />
		</div>
	  );
}

export function cassettePopupRows(forManufacturer) {
	var popupRows = [];
	for (var i = 0; i < cassettes.length; i++) {
		if (forManufacturer === cassettes[i].manufacturer) {
			let cassetteName = cassettes[i].range + " " +cassettes[i].shorthand() + " " + cassettes[i].speed() + " sp";
			popupRows.push({value:i, label:cassetteName});
			}
		}

	return popupRows;
}


export function readCassetteData() {
	cassettes = [];
	
	// Sort order for manufacturer's product names - highest to lowest quality in each type (MTB, Road)
	// There's some overlap, so it's judgement call on where sone ranges go (i.e. GRX is Shimano, Ekar in Campag)
	const Shimano = ["Dura-Ace", "Ultegra", "105", "GRX", "Tiagra", "Cue", "Sora", "Claris", "HG500", "HG50", "XTR", "Deore XT", "Deore", "SLX", "Alivio", "Acera", "Altus", "Tourney"];
	const SRAM = ["XX Eagle SL", "XX Eagle", "XG-1299", "XO Eagle", "XX1 Eagle", "X01 Eagle", "NX Eagle", "SX Eagle", "Mini-Block", "GX DH", "E-BLOCK"];
	const SRAMRoad = ["RED XG-1290", "RED XG-1190", "Force XG-1270", "XPLR XG-1271", "XG-1275", "XPLR XG-1251", "Rival XG-1250", "XG-1199", "XG-1195", "XG-1175", "PG-1170", "PG-1130", "XG-1150", "PG-1070", "PG-1050", "PG-1020", "PG-1030", "PG-970", "PG-950", "PG-920", "PG-850", "PG-830", "PG-820", "PG-730"];
	const Campag = ["Ekar", "Super Record", "Record", "Record/Chorus", "Chorus", "Centaur"];
	
	const ranges = Shimano.concat(SRAM, SRAMRoad, Campag);

	for (var i = 0; i < cassetteData.length; i++) {
		var obj = cassetteData[i];
		let thisCassette = new Cassette(obj.Manufacturer, obj.Range, obj.Product, obj.Gears);
		cassettes.push(thisCassette);
	}
	
	// Sort-Array function call to sort cassettes by Manufacturer, Range, number of gears and starting gear
	sortArray(cassettes, {by: ['manufacturer', 'range', 'Speeds', 'SmallestGear'], 
							order: ['asc', 'range', 'desc', 'desc'],
							computed: { Speeds: cassette => cassette.speed(), SmallestGear: cassette => cassette.smallestGear()},
							customOrders: {range: ranges}
							});

	return cassettes;
}

export function cassetteFromHash(hash) {
	const cassettes = readCassetteData();
	for (var i = 0; i < cassettes.length; i++) {
		if (cassettes[i].id == hash) {
			return cassettes[i];
		}
	}
}


class Cassette {
	constructor(manufacturer, range, product, gears) {
		this.manufacturer = manufacturer;
		this.range = range;
		this.product = product;
		this.gears = gears;
		this.gearsArray = this.gears.split("-");
		this.id = (manufacturer + product + gears).hashCode();

	}
	
	speed() {
		return this.gearsArray.length;
	}
	
	smallestGear() {
		return parseInt(this.gearsArray[0]);
	}
	
	shorthand() {
		return this.gearsArray[0] + "-" + this.gearsArray[this.gearsArray.length - 1] + "T";
	}
	
	name() {
		return this.range + " " + this.shorthand() + " " + this.speed() + " sp";
	}
}

// eslint-disable-next-line no-extend-native
String.prototype.hashCode = function() {
  var hash = 0,
    i, chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}



