import React from 'react';

export class ResultsTable extends React.Component {

	render() {
		return (
			<div className="table-responsive">
				<table className="table table-sm table-striped table-hover">
					<thead>
					<tr>
						<td className='effectiveWheelSize'>/</td>
					  <TableHeaderRow data={this.props.cassette} />
					</tr>
					</thead>
					<tbody>
				  <TableRow data={this.props.data} chainset={this.props.chainset} cassette={this.props.cassette} roundTo={this.props.roundTo} />
				  </tbody>
				</table>
			</div>
		  );
		}
}


const TableHeaderRow = ({data}) => {
	if (data) {
		var nn = 0;
	  return data.map((data) =>
      <th style={cellCentreStyle} key={nn++} className='cassetteGearSize'>{data}</th>
	  );
  } else {
	  return (<th>-</th>);
  }
}

const TableRow = ({data, chainset, cassette, roundTo}) => {
	var rows = [];
	
	if (data && chainset && cassette) {
		
		for (var mm = 0; mm < chainset.length; mm++) {
			var rowStart = mm * (cassette.length);
			var rowEnd = rowStart + cassette.length;

			var row = data.slice(rowStart, rowEnd);
			rows.push(row);
			var nn = 100;
		}
		return rows.map((row, index) => (
			<tr key={nn++} >
				<td style={cellCentreStyle} key={nn++} className='chainringSize'>
				{chainset[index]}
				</td>
			 {row.map((val) => <td key={nn++} className='effectiveWheelSize'>{val.toFixed(roundTo)}</td>)}
			</tr>
		));
  } else {
	  return (<tr><td>-</td></tr>);
  }
}

export class RPMResultsTable extends React.Component {

	render() {
	return (
		<div className="table-responsive">
			<table className="table table-sm table-striped table-hover">
				<thead>
				<tr>
					<td className='chainringByCassetteSize'>/</td>
				  <RPMTableHeaderRow data={this.props.headerData} />
				</tr>
				</thead>
				<tbody>
			  <RPMTableRow data={this.props.data} chainset={this.props.chainset} cassette={this.props.cassette} roundTo={this.props.roundTo} frontByRear={this.props.gearRatios} colourCells={this.props.colourCells} />
			  </tbody>
			</table>
		</div>
	  );
	}
}

const RPMTableHeaderRow = ({data}) => {
	if (data) {
		var nn = 0;
	  return data.map((data) =>
      <th style={cellCentreStyle} key={nn++} className='cassetteGearSize'>{data}</th>
	  );
  } else {
	  return (<th>-</th>);
  }
}

const RPMTableRow = ({data, chainset, cassette, roundTo, frontByRear, colourCells}) => {
	
	if (data && chainset && cassette) {
		
		var nn = 0;
		var rowCount = 0;
		
		return data.map((row, index) => (
			
			<tr key={nn++}>
				<td key={nn++} className='chainringByCassetteSize'>
				{frontByRear[rowCount++]}
				</td>
			 {row.map((val) => <td style={cellCentreStyle} key={nn++} className={((val > 120 || val < 60) && colourCells) ? 'table-danger' : 'table-default'}>{val.toFixed(roundTo)}</td>)}
			</tr>
		));
  } else {
	  return (<tr><td>-</td></tr>);
  }
}

const cellCentreStyle = {textAlign: 'center'};