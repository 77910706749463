import React from 'react';
import Select from 'react-select';
import tyreData from './tyres.json'

var tyres = []; 

export function TyrePopup(props) {
	readTyreData();
	const options = tyrePopupRows();
		
	let cookieTyre = props.tyre;
	
	// Find the index of the selected tyre
	var cookieIndex = -1;
	options.forEach((item, index) => {
		if (item.label === cookieTyre){
			cookieIndex = index;
		}
	});
		
	var itemToShow = {value: -1, label: 'Select...'};
	if (cookieIndex > -1) {
		itemToShow = options[cookieIndex];
	}
	
	  return (
		<div className="mt-3">
		Select your tyre size
		  <Select
			onChange={props.onChange}
		  	options={options}
		  	value={itemToShow}
		  />
		</div>
	  );
}


export function readTyreData() {
	tyres = [];

	for (var i = 0; i < tyreData.length; i++) {
		var obj = tyreData[i];
		let thisTyre = new Tyre(obj.name, obj.value);
		tyres.push(thisTyre);
	}
	
	return tyres;
	
	
}

export default class Tyre {
	constructor(name, value) {
		this.name = name;
		this.size = value
		}
}

function tyrePopupRows() {
	var popupRows = [];
	for (var i = 0; i < tyres.length; i++) {
		let tyreName = tyres[i].name;
		popupRows.push({value:i, label:tyreName});
		}
	
	return popupRows;
}
