import React from 'react';
import Select from 'react-select';
import wheelData from './wheels.json'

var wheels = []; 

export function WheelPopup(props) {

	readWheelData();
	const options = wheelPopupRows();

	let cookieWheel = props.wheel;
	
	// Find the index of the selected Wheel
	var cookieIndex = -1;
	options.forEach((item, index) => {
		if (item.label === cookieWheel){
			cookieIndex = index;
		}
	});
	
	var itemToShow = {value: -1, label: 'Select...'};
	if (cookieIndex > -1) {
		itemToShow = options[cookieIndex];
	}
	
	  return (
		<div className="mt-3">
		Select your wheel size
		  <Select
			onChange={props.onChange}
		  	options={options}
		  	value={itemToShow}
		  />
		</div>
	  );
}


export function readWheelData() {
	wheels = [];

	for (var i = 0; i < wheelData.length; i++) {
		var obj = wheelData[i];
		let thisWheel = new Wheel(obj.title, obj.size);
		wheels.push(thisWheel);
	}
	return wheels;
}


function wheelPopupRows() {
	var popupRows = [];
	for (var i = 0; i < wheels.length; i++) {
		let wheelName = wheels[i].title;
		popupRows.push({value:i, label:wheelName});
		}
	
	return popupRows;
}


export class Wheel {
	constructor(title, size) {
		this.title = title;
		this.size = size
		}
}
