import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';





export const EffectiveWheelSizePopover = () => {
	return infoPopover(ewsPopover);
}

export const DevelopmentMetresPopover = () => {
	return infoPopover(dmPopover);
}

export const WheelCircumferencePopover = () => {
	return infoPopover(wcPopover);
}

export const RpmForSpeedPopover = () => {
	return infoPopover(rpmSpeedPopover);
}

export const SpeedForRPMPopover = () => {
	return infoPopover(speedRPMPopover);
}



const infoPopover = (overlay) => {
	return 	<OverlayTrigger trigger="click" placement="right" overlay={overlay} rootClose="true">
				<Button variant="outline-secondary" size="sm"><Icon.InfoSquareFill size={20} /></Button>
			</OverlayTrigger>
}

const ewsPopover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">Effective Wheel Size</Popover.Header>
    <Popover.Body>
		Effective wheel Size is based on the equivalent diameter of the drive wheel found on high-wheel or Penny-Farthing bicycles. This same system was also used on chain-drive "safety" bikes by multiplying the drive wheel diameter by the sprocket ratio. Calculating the gear value using this system is very simple: just multiply the diameter of the drive wheel by the size of the front sprocket and divide by the size of the rear sprocket, resulting in a two- or three-digit number. On most mountain bikes, the lowest gear is usually around 22-26 inches, while on road racing bikes, the highest gear typically falls around 108-110 inches.
    </Popover.Body>
  </Popover>
);

const dmPopover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">Development Metres</Popover.Header>
    <Popover.Body>
		Development, also referred to as rollout, is another method used for numbering gear values in bicycle gears. This system measures the distance that a bicycle travels for one revolution of the crank in meters. Calculating the development value is done by dividing the size of the chainwheel by the size of the rear sprocket, then multiplying the result by the diameter of the wheel, and by pi (3.1416).
    </Popover.Body>
  </Popover>
);

const wcPopover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">Wheel Circumference</Popover.Header>
    <Popover.Body>
This shows the calculated circumference of your wheel using the data entered above. It is based on the wheel's Bead Seat Diameter and the tyre size. It doesn't allow for any distortion caused by the weight of the bike and rider. To get the most accurate calculations, or if you have a wheel & tyre combination not listed above, measure the circumference of your wheel and enter the size, in mm, here.    </Popover.Body>
  </Popover>
);

const rpmSpeedPopover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">RPM for Speed</Popover.Header>
    <Popover.Body>
This table shows the pedalling cadence required for a particular speed. Cadence values outside the 'normal' range (less that 60 or more than 120) are shown in red.  </Popover.Body>
  </Popover>
);

const speedRPMPopover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">Speed for RPM</Popover.Header>
    <Popover.Body>
This table shows the speed attained at a pedalling cadence for each gear combination. </Popover.Body>
  </Popover>
);

