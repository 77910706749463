
export class Bike {
	constructor(cassetteID, cassetteGears, chainset, tyre, wheel, wheelCircumference, name) {
		this.cassetteID = cassetteID;
		this.cassetteGears = cassetteGears;
		this.chainset = chainset;
		this.tyre = tyre;
		this.wheel = wheel;
		this.wheelCircumference = wheelCircumference;
		this.name = name;
// 		console.log(this);

	}
	
	cookieString() {
		return JSON.stringify(this);
	}
}

export function bikeFromCookieString(cookie) {
	return JSON.parse(cookie);
}
