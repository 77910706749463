import React from 'react';
import ReactDOM from 'react-dom/client';


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { saveAs } from 'file-saver';
import CookieConsent from "react-cookie-consent";


import * as Icon from 'react-bootstrap-icons';

// Bootstrap Imports
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';

import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';

import {ResultsTable, RPMResultsTable} from './ResultsTable';
import {WheelPopup, readWheelData} from './wheelpopup';
import {TyrePopup, readTyreData} from './TyrePopup';
import {ManufacturerPopup} from './ManufacturerPopup';
import {readCassetteData, CassettePopup, cassetteFromHash} from './CassettePopup';
import {getCookie, setCookie} from './cookie';
import {GearSizeGraph, CassetteStepGraph} from './Graphs';
import {EffectiveWheelSizePopover, DevelopmentMetresPopover, WheelCircumferencePopover, RpmForSpeedPopover, SpeedForRPMPopover} from './popovers';
import {SaveBikeDialog, EditBikeDialog} from './SaveDialog';
import {Bike} from './bike';
import {TopNavBar} from './NavBar';
import crankarm from './crankarm.png';
import wheelIcon from './wheel.png';
import cassetteIcon from './cassette.png';
import chainIcon from './chain.png';

var cassettes = [];
var wheels = [];
var tyres = [];
var wheelDia = 0;
var tyreSize = 0;
var cadenceFigures = [50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120];
var speedFigures = [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35];
var speedFiguresMiles = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 28, 30, 35];


class GearSize extends React.Component {

	render() {
		let name = this.props.base + this.props.value;

		return (
		<Col>
			<div className="form-group">
				<label>{this.props.value}<br /></label>
				<input type="text" name={name} id={name} defaultValue={this.props.size} onChange={this.props.onChange} size="2"/>
			</div>
		</Col>
		);
	}
}

class App extends React.Component {


	 constructor(props) {
		super(props);
		
		let cookieCassette = this.readCassetteCookie();
		let cookieManu = this.readManufacturerCookie();
		let cookieWheel = this.readWheelCookie();
		let cookieTyre = this.readTyreCookie();
		let cookieCassetteGears = getCookie('cassettegears');
		let cookieWheelCircum = this.readWheelCircumferenceCookie();
		
		this.state = {selectedManufacturer: cookieManu,
						selectedCassette: cookieCassette,
						selectedWheel: cookieWheel,
						selectedTyre: cookieTyre,
						selectedCassetteGears: cookieCassetteGears,
						wheelCircumference: cookieWheelCircum,
						effective: [],
						cassGears: [],
						chainsetGears: [],
						development: [],
						frontByBack: [],
						speedAtRPM: [],
						rpmAtSpeed: [],
						cassetteSteps: [],
						cassetteStepsPercent: [],
						clearCassetteSelection: false,
						useImperial: false,
						resultsClass: "hideResultsPanel",
						modalShow: false,
						modelEditShow: false,
						savedBikes: [],
						shouldRecalculate: false,
						enableCalcButton: false,
						};
	}	
	
componentDidMount() {

	// Set the document title
	document.title = 'Gear Calculator';

	// Load wheel data
	wheels = readWheelData();
	let wheelCookie = this.readWheelCookie();
	wheelDia = wheelSizeFromName(wheelCookie);
	
	// Load Tyre data
	tyres = readTyreData();
	let tyreCookie = this.readTyreCookie();
	tyreSize = tyreSizeFromName(tyreCookie);

    // Show the cassette data if one is saved in a cookie
    cassettes = readCassetteData();
	
	// If there's no cookie set, display the gears for the default cassette
	let cassetteID = Number(this.state.selectedCassette)
	let defaultCassette = cassetteFromID(cassetteID);
	if (defaultCassette > 0) {
		displayCassetteGears(defaultCassette);
	}
	else {
		const gearsArray = this.state.selectedCassetteGears.split(",");
		if (gearsArray.length > 1) {
			console.log("In componentDidMount, gearsArray");
			console.log(gearsArray);
		
			//Enable the Calculate Button
			this.setState({enableCalcButton: true});
			displayCassetteGearsFromArray(gearsArray);
		}
		else {
			displayCassetteGearsFromArray(['12', '13', '14', '15', '16', '17', '18', '19', '21', '23', '25']);
		}
	}
	
	// If we've used custom sizes, display those.
	const gearsArray = this.state.selectedCassetteGears.split(",");
	if (gearsArray.length > 1) {
		
		//Enable the Calculate Button
		this.setState({enableCalcButton: true});

	}
	
	this.retrieveSavedBikes();
	
	// By this point, we should have all the data necessary for the calculations
	this.setState({enableCalcButton: true});
	this.setState({shouldRecalculate: true});
	
	this.shouldRecalculate();
}  
  


calculate = (useImperial) => {

	// Set Recalculate flag to true so that we'll recalculate every time the inputs change
	this.setState({recalculate: true});

	// Check we've got enough data - at least one chainring and one gear in the cassette
	// Retrieve the cassette gears into an array - get from the input fields rather than 
	// loading from the cassette as the user may have changed one or more of the sizes
	const cassetteGears = [];
	for (var i = 1; i <= 13; i++) {
		let name = "gear" + (i);
		let field = document.getElementById(name);
		let fieldValue = field.value;
		if (fieldValue !== "") {
			cassetteGears.push(fieldValue);
		}
	}
	
	// Save the chainset gears as a cookie
	this.saveGearsinCookie(cassetteGears);
	
		
	const chainsetGears = []; 
	for (var ii = 1; ii <= 3; ii++) {
		let name = "chainset" + (ii);
		let field = document.getElementById(name);
		let fieldValue = Number(field.value);
		if (fieldValue > 0) {
			chainsetGears.push(fieldValue);
			}
		}
		
	// Save the chainset gears as a cookie
	const cookieString = chainsetGears.join(",");
	setCookie("chainrings", cookieString, 365);
	
		
	// Get the Wheel circumference
	let field = document.getElementById('wheelCircum');
	var wheelC = field.value;
	var wheelDiameter = (wheelC/Math.PI)/25.4;
	
	// Calculate cassette step size
	const cassetteSteps = [];
	const cassetteStepsPercent = [];
	for (var m = 0; m < (cassetteGears.length - 1); m++) {
		let stepSize = cassetteGears[m + 1] - cassetteGears[m];
		let stepSizePercent = (stepSize/cassetteGears[m]) * 100.0;
		cassetteSteps.push(stepSize);
		cassetteStepsPercent.push(stepSizePercent);
	}
	this.setState({cassetteSteps: cassetteSteps});
	this.setState({cassetteStepsPercent: cassetteStepsPercent});
	
		
	// Calculate effective wheel size
	const efwhsz = [];
	const develop = [];
	const spdAtRPM = [];
	const rpmAtSpd = [];
	const gears = [];
	for (var nn = 0; nn < chainsetGears.length; nn++) {
		let chainsetGear = chainsetGears[nn];
		for (var mm = 0; mm < cassetteGears.length; mm++) {
			let cassetteGear = cassetteGears[mm];
			let effectiveWheelSize = wheelDiameter * (chainsetGear/cassetteGear);
			let wheelDev = (chainsetGear/cassetteGear) * Number(wheelC);
			
			var gearForCalc = chainsetGear + " x " + cassetteGear;
			gears.push(gearForCalc);
			const cadenceForGear = [];
			cadenceFigures.forEach((cadence) => {
				let speedAtRPM = (((wheelDev/1000) * cadence)/1000) * 60;
				if (useImperial) {
					speedAtRPM = speedAtRPM/1.609;
				}
				cadenceForGear.push(speedAtRPM);
				});
				
			const speedForGear = [];
			if (useImperial) {
				// Imperial Calcs
				speedFiguresMiles.forEach((speed) => {
					let gearRatio = chainsetGear/cassetteGear;
					speed = speed * 1.609; // Convert mph to km/hr for calculation
					let speedmpmin = speed * 16.666667; // convert km/hr to m/min
					let wheelRotations = speedmpmin/(Number(wheelC)/1000.0); // convert to number of wheel rotations per min
					let rpmRequired = wheelRotations/gearRatio;
					speedForGear.push(rpmRequired);
					});
			}
			else {
				// Metric Calcs
				speedFigures.forEach((speed) => {
					let gearRatio = chainsetGear/cassetteGear;
					let speedmpmin = speed * 16.666667; // convert km/hr to m/min
					let wheelRotations = speedmpmin/(Number(wheelC)/1000.0); // convert to number of wheel rotations per min
					let rpmRequired = wheelRotations/gearRatio;
					speedForGear.push(rpmRequired);
					});
			}

			
			efwhsz.push(effectiveWheelSize);
			develop.push(wheelDev/1000);
			spdAtRPM.push(cadenceForGear);
			rpmAtSpd.push(speedForGear);
		}
	}
	this.setState({resultsClass: "showResultsPanel"});
	this.setState({effective: efwhsz});
	this.setState({development: develop});
	this.setState({cassGears: cassetteGears});
	this.setState({chainsetGears: chainsetGears});
	this.setState({speedAtRPM: spdAtRPM});
	this.setState({frontByBack: gears});
	this.setState({rpmAtSpeed: rpmAtSpd});
}

readTyreCookie = () => {
	// Read the Tyre cookie and return a default value if it's not set
	let tyreCookie = getCookie('tyre');
	if (tyreCookie === "") {
		tyreCookie = "23 mm";
	}
	return tyreCookie;
}

readWheelCookie = () => {
	// Read the Wheel cookie and return a default value if it's not set
	let wheelCookie = getCookie('wheel');
	if (wheelCookie === "") {
		wheelCookie = "700c/29er (622 mm)";
	}
	return wheelCookie;
}

readManufacturerCookie = () => {
	// Read the Manufacturer cookie and return a default value if it's not set
	let manuCookie = getCookie('manufacturer');
	if (manuCookie === "") {
		manuCookie = "Shimano";
	}
	return manuCookie;
}

readCassetteCookie = () => {
	// Read the Cassette cookie and return a default value if it's not set
	let cassetteCookie = getCookie('cassette');
	if (cassetteCookie === "") {
		// Dura-Ace 11sp 11-25 Cassette
		cassetteCookie = "-1059397560";		
	}
	return cassetteCookie;
}

readWheelCircumferenceCookie = () => {
	// Read the Wheel circumference cookie and return a default value if it's not set
	let wheelCirCookie = getCookie('wheelCircum');;
	if (wheelCirCookie === "") {
		wheelCirCookie = "2099";
	}
	return wheelCirCookie;
}


onChangeManufacturer = (val) => {
	this.setState({selectedManufacturer: val.label});
	setCookie("manufacturer", val.label, 365);
	this.setState({selectedCassette: ""});
	cassetteClearFields();
}

onChangeCassette = (val) => {
	var cassetteID = val.value;
	cassetteClearFields();
	var selected = cassettes[cassetteID];
	setCookie("cassette", selected.id, 365);

	displayCassetteGears(selected);
	const gearsArray = selected.gearsArray;
	// Save the cassette gears as a cookie
	const cookieCassetteString = gearsArray.join(",");
	setCookie("cassettegears", cookieCassetteString, 365);

	this.setState({selectedCassette: selected.id});
	this.setState({enableCalcButton: true});
	
	this.shouldRecalculate();
}

onCassetteGearChange = (val) => {
	// Set cassette to 'custom'
	// Set the Hash to 0 for custom
	this.setState({selectedCassette: 0});
	const cogs = this.cassetteGearsAsArray();
	// Save the cassette gears as a cookie 
	this.saveGearsinCookie(cogs);
	this.shouldRecalculate();
}

onChainsetGearChange = (val) => {
	// Set cassette to 'custom'
	// Set the Hash to 0 for custom
// 	this.setState({selectedCassette: 0});
// 	const cogs = this.cassetteGearsAsArray();
// 	// Save the chainset gears as a cookie 
// 	this.saveGearsinCookie(cogs);
	this.shouldRecalculate();
}


onWheelChange = (val) => {
	var wheelID = val.value;
	setCookie("wheel", val.label, 365);
	this.setState({selectedWheel:val.label});
	var selected = wheels[wheelID];
	wheelDia = selected.size;
	let wheelCircum = calculateWheelDiameter();
	this.setState({wheelCircumference: wheelCircum});
	this.shouldRecalculate();
}

onTyreChange = (val) => {
	var tyreID = val.value;
	setCookie("tyre", val.label, 365);
	this.setState({selectedTyre:val.label});
	var selected = tyres[tyreID];
	tyreSize = selected.size;
	let wheelCircum = calculateWheelDiameter();
	this.setState({wheelCircumference: wheelCircum});
	this.shouldRecalculate();
}

onUnitsChange = (val) => {
	if (val.target.value === 'imperial') {
		this.setState({useImperial: true});
		this.calculate(true);
	}
	else {
		this.setState({useImperial: false});
		this.calculate(false);
	}
}

onCalculate = (val) => {
	this.calculate(false);
}

onSelectNewBike = (eventKey, event) => {
	// Save a bike
	if (parseInt(eventKey) === 99) {
		this.setState({modalShow: true});
	}
	// Manage Bikes
	if (parseInt(eventKey) === 999) {
		this.setState({modalEditShow: true});
	}
	// Display a saved bike
	else {
		let bike = this.state.savedBikes[parseInt(eventKey)]
		this.displayBike(bike);
	}
}

onModalHide = (val) => {
// 	console.log(val);
	this.setState({modalShow: false});
	// Save the current settings as a new bike
	let name = this.state.bikeName;
	let cassette = this.state.selectedCassette;
	let cassetteGears = this.readCassetteGears();
	let wheel = this.state.selectedWheel;
	let tyre = this.state.selectedTyre;
	let wheelCircumference = this.state.wheelCircumference;
	let chainset = this.readChainsetGears();
	
	let newBike = new Bike(cassette, cassetteGears, chainset, tyre, wheel, wheelCircumference, name);
	
	const bikeArray = this.state.savedBikes;
	bikeArray.push(newBike);
	this.setState({saveBikes: bikeArray});
	this.writeSavedBikes();
	
}

onModalCancel = (val) => {
	this.setState({modalShow: false});
}

onNameChange = (e) => {
	this.setState({bikeName: e.target.value});
}

// For Edit Bike model
onEditModalHide = (val) => {
	this.setState({modalEditShow: false});
}

onEditModalCancel = (val) => {
// 	console.log("Cancelled");
	this.setState({modalEditShow: false});
}

onEditModalDelete = (e) => {
	console.log("In onEditModalDelete");
	console.log(e);
	let bikes = this.state.savedBikes;
	bikes.splice(e, 1);
	this.setState({savedBikes: bikes});
	this.writeSavedBikes();
	this.setState({modalEditShow: false});
}

retrieveSavedBikes = () => {
	let cookieSavedBikes = getCookie('savedBikes');
	if (cookieSavedBikes) {
		let bikes = JSON.parse(cookieSavedBikes);
		this.setState({savedBikes: bikes});
	}
}

writeSavedBikes = () => {
	const bikeJSON = JSON.stringify(this.state.savedBikes);
	setCookie('savedBikes', bikeJSON, 365);
}

displayBike(bike) {
	const bikeCassette = cassetteFromHash(bike.cassetteID);
	this.setState({selectedCassetteGears: bike.cassetteGears});
	displayCassetteGearsFromArray(bike.cassetteGears);
	
	displayChainsetGearsFromArray(bike.chainset);
	
	this.displayWheelCircumference(bike.wheelCircumference);
	
	this.setState({cassGears: bike.cassetteGears});
	this.setState({selectedManufacturer: bikeCassette.manufacturer});
	this.setState({selectedCassette: bikeCassette.id});
	this.setState({selectedWheel: bike.wheel});
	this.setState({selectedTyre: bike.tyre});
	this.setState({wheelCircumference: bike.wheelCircumference});
}

cassetteGearsAsArray() {
	const cassetteGears = [];
	for (var i = 1; i <= 13; i++) {
		let name = "gear" + (i);
		let field = document.getElementById(name);
		let fieldValue = field.value;
		console.log(fieldValue);
		if (fieldValue !== "") {
			cassetteGears.push(fieldValue);
		}
	}
	return cassetteGears;
}

saveGearsinCookie(gearsArray) {
	// Save the chainset gears as a cookie
	// Also updates the selectedCassetteGears state variable
	const cookieCassetteString = gearsArray.join(",");
	this.setState({selectedCassetteGears: cookieCassetteString}); 
	setCookie("cassettegears", cookieCassetteString, 365);
}

displayWheelCircumference(wheelCircumference) {
	let field = document.getElementById('wheelCircum');
	field.value = Math.round(wheelCircumference);
	this.setState({wheelCircumference: wheelCircumference});
}

exportRPMForSpeedData = (e) => {
	if (this.state.useImperial) {
		this.exportToCSV(speedFiguresMiles, this.state.frontByBack, this.state.rpmAtSpeed);
	}
	else{
		this.exportToCSV(speedFigures, this.state.frontByBack, this.state.rpmAtSpeed);	
	}
	
}

exportSpeedForRPMData = (e) => {	
	this.exportToCSV(cadenceFigures, this.state.frontByBack, this.state.speedAtRPM);
}


exportToCSV(header, column1, data) {
	let headerRow = " ," + header.join(",") + "\n";
	console.log(headerRow);
	let datacsv = "";
	data.forEach((dataRow, index) => {
		let row = column1[index] + ',' + dataRow.join(",") + "\n";
		datacsv += row;
	});
	
	const csvFile = headerRow + "\n" + datacsv;
	
	const filename = "rpmforspeed.csv";
		
	var blob = new Blob([csvFile], {type: "text/plain;charset=utf-8"});
	saveAs(blob, filename);
}

readChainsetGears() {
	const chainsetGears = []; 
	for (var ii = 1; ii <= 3; ii++) {
		let name = "chainset" + (ii);
		let field = document.getElementById(name);
		let fieldValue = Number(field.value);
		if (fieldValue > 0) {
			chainsetGears.push(fieldValue);
			}
		}
	return chainsetGears;
	}

readCassetteGears() {
	const cassetteGears = [];
	for (var i = 1; i <= 13; i++) {
		let name = "gear" + (i);
		let field = document.getElementById(name);
		let fieldValue = Number(field.value);
		if (fieldValue > 0) {
			cassetteGears.push(fieldValue);
		}
	}
	return cassetteGears;
}

onCircumferenceChange = (event) => {
	let circum = event.target.value;
	setCookie("wheelCircum", circum, 365);
	this.setState({wheelCircumference: circum});

	this.shouldRecalculate();
}


shouldRecalculate = () => {
	if (this.state.recalculate) {
		this.calculate();
	}
}

render() {
	let speedHeader = speedFigures;
	if (this.state.useImperial) {
		speedHeader = speedFiguresMiles;
	}
	return (
	<div className="inputPage">
		<CookieForm />
		
		<div>
		<Container fluid='xxl'>
			<TopNavBar onSelect={this.onSelectNewBike} bikesArray={this.state.savedBikes} />
			      
			<Row xs={1} md={2} >
				<Col className='border-end border-3'>
					<Stack direction="horizontal" gap={2}><img src={chainIcon} alt="Chain" width='20' className="mt-2" /><h4 className="mt-2">Drive Chain</h4></Stack>
					<Stack direction="horizontal" gap={2}><img src={cassetteIcon} alt="Cassette" width='20' className="mt-2" /><h5 className='popupDescription, mt-2'>Cassette</h5></Stack>
					<ManufacturerPopup onChange={this.onChangeManufacturer} manufacturer={this.state.selectedManufacturer} />
					<CassettePopup manufacturer={this.state.selectedManufacturer} cassette={this.state.selectedCassette} onChange={this.onChangeCassette} />
					<div className="mt-3">
						<strong>Cassette Cogs</strong>
						{ cassette(this.onCassetteGearChange) }
					</div>
					<Stack direction="horizontal" gap={2}><img src={crankarm} alt="crankarm" width='20'className='popupDescription, mt-3'/><h5 className='popupDescription, mt-3'>Chainset</h5></Stack>
					{chainset(this.onChainsetGearChange) }

				</Col>
				<Col>
					<Stack direction="horizontal" gap={2}><img src={wheelIcon} alt="Wheel" width='20' className="mt-2" /><h4 className="mt-2">Wheel Setup</h4></Stack>
					<Stack direction="horizontal" gap={2}><img src={cassetteIcon} alt="Cassette" width='20' className="hiddenIcon" /><h5 className='popupDescription, mt-2'>&nbsp;</h5></Stack>
					<WheelPopup wheel={this.state.selectedWheel} onChange={this.onWheelChange} />
					<TyrePopup tyre={this.state.selectedTyre} onChange={this.onTyreChange} />
					<WheelDiameter wheelCircumference={this.state.wheelCircumference} onChange={this.onCircumferenceChange} />
				</Col>
			</Row>
			<Row xs={1} md={2} >
				<Col className='border-end border-3'>
				<div className='calculateButton'>
				<CalculateButton onClick={this.onCalculate} enabled={this.state.enableCalcButton} />
				</div>
				</Col>
				<Col>
				</Col>
			</Row>
		</Container>
		
		<Container fluid='lg'>		
			<div className={this.state.resultsClass}>
				<Tabs>
					<TabList>
					  <Tab>Cassette Details</Tab>
					  <Tab>Effective Wheel Size</Tab>
					  <Tab>Speed at RPM</Tab>
					  <Tab>RPM at Speed</Tab>
					</TabList>
					<TabPanel>
					<h3>Cassette Details</h3>
					<CassetteDetails cassette={this.state.cassGears} />
					<CassetteStepGraph cassetteSteps={this.state.cassetteSteps} cassetteStepsPercent={this.state.cassetteStepsPercent} />
					</TabPanel>
					<TabPanel>
						<Stack direction="horizontal" gap={2}>
							<h3>Effective Wheel Size</h3>
							<EffectiveWheelSizePopover />
						</Stack>
						<ResultsTable chainset={this.state.chainsetGears} cassette={this.state.cassGears} data={this.state.effective} roundTo={0} />
						<Stack direction="horizontal" gap={2}>
							<h3>Development Metres</h3>
							<DevelopmentMetresPopover />
						</Stack>
						<ResultsTable chainset={this.state.chainsetGears} cassette={this.state.cassGears} data={this.state.development} roundTo={2} />
						<GearSizeGraph cassetteGears={this.state.cassGears} effective={this.state.effective} chainsetGears={this.state.chainsetGears}/>
					</TabPanel>
					<TabPanel>
						<SpeedForRPMHeader useImperial={this.state.useImperial} onClickExport={this.exportSpeedForRPMData} />
							<Form>
								<Form.Check type="radio" aria-label="Metric" label="Metric" name="units" inline="true" value="metric" onChange={this.onUnitsChange} defaultChecked />
								<Form.Check type="radio" aria-label="Imperial" label="Imperial" name="units" inline="true" value="imperial" onChange={this.onUnitsChange} />
							</Form>
						<RPMResultsTable chainset={this.state.chainsetGears} cassette={this.state.cassGears} data={this.state.speedAtRPM} roundTo={2} gearRatios={this.state.frontByBack} headerData={cadenceFigures} colourCells={false}/>
					</TabPanel>
					<TabPanel>
						<RPMForSpeedHeader useImperial={this.state.useImperial} onClickExport={this.exportRPMForSpeedData} />
							<Form>
								<Form.Check type="radio" aria-label="Metric" label="Metric" name="units" inline="true" value="metric" onChange={this.onUnitsChange} defaultChecked />
								<Form.Check type="radio" aria-label="Imperial" label="Imperial" name="units" inline="true" value="imperial" onChange={this.onUnitsChange} />
							</Form>
						<RPMResultsTable chainset={this.state.chainsetGears} cassette={this.state.cassGears} data={this.state.rpmAtSpeed} roundTo={0} gearRatios={this.state.frontByBack} headerData={speedHeader} colourCells={true} />
					</TabPanel>
				</Tabs>
			</div>
		</Container>
		</div>

      <SaveBikeDialog
        show={this.state.modalShow}
        onHide={this.onModalHide}
        onCancel={this.onModalCancel}
        onNameChange={this.onNameChange}
      />
      
      <EditBikeDialog
        show={this.state.modalEditShow}
        bikesArray={this.state.savedBikes}
        onHide={this.onEditModalHide}
        onCancel={this.onEditModalCancel}
        onNameChange={this.onNameChange}
        onDelete={this.onEditModalDelete}
      />
      	
	<div className="clear"></div>	
	</div>
		);
	}
}

function cassette(onCassetteFunc) {
	const rows = [];
	for (let i = 1; i <= 13; i++) {
		rows.push(<GearSize
					key={i}
					value={i}
					base={"gear"}
					onChange={onCassetteFunc}
				/>);
	}
// 	return <Container>
// 			<Row xs={5} xl={13}>
// 			{rows}
// 			</Row>
// 			</Container>;
	return 	<Row xs={5}>
			{rows}
			</Row>;
}


function chainset(onChainsetChangeFunc) {
	let cookieChainrings = getCookie('chainrings');
	
	// Default chainring values
	let chainRings = [34,50];
	if (cookieChainrings) {
		chainRings = cookieChainrings.split(",");
	}
	const rows = [];
	for (let i = 1; i <= 3; i++) {
		rows.push(<GearSize
					key={i}
					value={i}
					size={chainRings[i-1]}
					base={"chainset"}
					onChange={onChainsetChangeFunc}
				/>);
	}
	return <Container>
			<Row>
			{rows}
			</Row>
			</Container>;
}

const SpeedForRPMHeader = (props) => {
	let popover = <SpeedForRPMPopover />;
	return RPMSpeedTableHeader(props.useImperial, "Speed at RPM", props.onClickExport, popover);
}

const RPMForSpeedHeader = (props) => {
	let popover = <RpmForSpeedPopover />;
	return RPMSpeedTableHeader(props.useImperial, "RPM at Speed", props.onClickExport, popover);
}

const RPMSpeedTableHeader = (units, tableTitle, onClick, popover) => {
	let unitsString = "";
	if (units) {
		unitsString = "mph";
	}
	else {
		unitsString = "km/hr";
	}
	return <Stack direction="horizontal" gap={2}><h3>{tableTitle} ({unitsString})</h3><Button variant="outline-secondary" size="sm" onClick={onClick}><Icon.ShareFill /></Button>{popover}</Stack>
}

const WheelDiameter = (props) => {

return (
	<div className="mt-3">
		Circumference of your wheel - enter manually for a custom size
		<Stack direction="horizontal" gap={2}>
			<input type="text" id='wheelCircum' size="6" onChange={props.onChange} defaultValue={props.wheelCircumference} /> mm
			<WheelCircumferencePopover />
		</Stack>
	</div>
	);
}

const CookieForm = () => {

	return  (   
	<CookieConsent
		  location="bottom"
		  buttonText="OK"
		  cookieName="cookieAccepted"
		  style={{ background: "#2B373B" }}
		  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
		  expires={150}
		>
		  This website uses cookies to enhance the user experience.
	</CookieConsent>
	);

}

function cassetteClearFields() {
	for (var i = 0; i < 13; i++) {
		let name = "gear" + (i + 1);
		let field = document.getElementById(name);
		field.value = "";
	}
}

function chainsetClearFields() {
	for (var i = 0; i < 3; i++) {
		let name = "chainset" + (i + 1);
		let field = document.getElementById(name);
		field.value = "";
	}
}

function cassetteFromID(id) {
	for (var i = 0; i < cassettes.length; i++) {
		if (id === cassettes[i].id) {
			return cassettes[i];
		}
	}
}

function displayCassetteGears(selected) {
	var gearsArray = selected.gearsArray;
	displayCassetteGearsFromArray(gearsArray);
}

function displayCassetteGearsFromArray(gearsArray) {
	cassetteClearFields();
	for (var i = 0; i < gearsArray.length; i++) {
		let name = "gear" + (i + 1);
		let field = document.getElementById(name);

		if (field) {
			field.value = gearsArray[i];
		}
	}
}

function displayChainsetGearsFromArray(gearsArray) {
	chainsetClearFields();
	for (var i = 0; i < gearsArray.length; i++) {
		let name = "chainset" + (i + 1);
		let field = document.getElementById(name);

		if (field) {
			field.value = gearsArray[i];
		}
	}
}

function calculateWheelDiameter() {
	if (tyreSize > 0 && wheelDia > 0) {
		var completeWheelDia = Number(wheelDia) + (2 * Number(tyreSize));
		var wheelCircum = completeWheelDia * Math.PI;
		let field = document.getElementById('wheelCircum');
		field.value = Math.round(wheelCircum);
		setCookie("wheelCircum", Math.round(wheelCircum), 365);
		return Math.round(wheelCircum);
	}
}

function wheelSizeFromName(wheelName) {
	let size = 0;
	wheels.forEach((wheel) => {
		if (wheelName === wheel.title) {
			size = wheel.size;
		}
	});
	return size;
}

function tyreSizeFromName(tyreName) {
	let size = 0;
	tyres.forEach((tyre) => {
		if (tyreName === tyre.name) {
			size = tyre.size;
		}
	});
	return size;
}

const CassetteDetails = (props) => {
	const smallestGear = props.cassette[0];
	const largestGear = props.cassette[props.cassette.length - 1];
	const percentageRange = largestGear/smallestGear * 100.0;
	
	return (
		<p>Cassette Range: {smallestGear} to {largestGear}, {Math.round(percentageRange)}%</p>
	);
}

const CalculateButton = (props) => {
	if (props.enabled) {
		return (<Button onClick={props.onClick} variant="outline-secondary" >Calculate</Button>);
	}
	else {
		return (<Button onClick={props.onClick} variant="outline-secondary" disabled >Calculate</Button>);
	}	
}



// ========================================

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
